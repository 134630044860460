<template>
  <div class="stand-in-footer__wrapper">
    <div class="stand-in-footer__content" />
  </div>
</template>

<script>
  export default {

  }

</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;

.stand-in-footer{
  &__wrapper{
    background-color: var(--body_color);
    color: var(--font_heavy);
  }

  &__content {
    max-width: $maximum-width;
    min-height: 50px;
    margin: auto;
    padding: 0 20px;
  }
}
</style>
